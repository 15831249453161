import { HttpResponse, delay, http as https } from 'msw'
import { QA_API_HOST, sandboxApi } from 'const/config'
import { testEndpointUrl } from 'services/testQuery'
import {
  AccountTypeMapping,
  accountActivityUrl,
  accountSummaryV2GetHandler as accountSummaryV2GetHandlerPaylater,
  autopayStatusUrl,
  creditAccountsQueryGetHandler
} from 'syf-api'
import {
  accountFeaturesUrl,
  paymentEnterpriseEligibilityUrl,
  paymentMethodsUrl,
  paymentQueryUrl,
  settlementEligibilityUrl,
  statementsUrl
} from 'syf-api/api/endpoints'
import mockAuthData from 'mocks/mockData/auth/mockAuthData'
import { introspectURL } from 'services/introspectQuery/introspectQuery'
import {
  activationAuthenticateURL,
  authenticateURL,
  paylaterAuthenticateURL
} from 'services/authenticateMutation/authenticateMutation.constants'
import { completeURL } from 'services/completeMutation/completeMutationOptions'
import { authorizeURL } from 'services/authorizeMutation/authorizeMutationOptions'
import { getMockAccountIds } from 'mocks/mockData'
import { SECURED_INSTALLMENTS_FLOW } from 'const/constants'
import getSessionAuthFlow from 'helpers/authFlows/getSessionAuthFlow'
import sleep from 'helpers/sleep'
import mockActivationAuthData from 'mocks/mockData/auth/activation/mockActivationAuthData'
import { PRE_LOGIN_ACTIVATE_URL } from 'const/universalActivation'
import { mockProfileData } from './mockData/profile/mockProfileData'
import { mockAlertData } from './mockData/alerts/mockAlertsData'
import {
  mockAccountFeatures,
  mockActivity,
  mockAutopayStatus,
  mockAutopayStatusEnrolled,
  mockMMAConfig,
  mockPaymentEnterpriseEligibility,
  mockPaymentMethods,
  mockSettlementEligibility,
  mockStatements
} from './mockData'
import { mockPreferencesData } from './mockData/alerts/mockPreferencesData'
import accountSummarySecuredInstallmentsMock from './mockData/accountSummary/securedInstallments'
import {
  activationSuccessResponse,
  cardActivationErrors,
  getActivationCustomErrorResponse
} from './mockData/card/activation/mockCardActivationData'
import { MOCK_ACTIVATION_CUSTOM_ERROR_CODE } from './ui/const/toggles'

/** Whether the environment is a browser or test runner */
export const isBrowserEnvironment = process.env.NODE_ENV !== 'test'

/**
 * Setup file for MSW (Mock Service Worker) to mock api calls
 * https://mswjs.io/docs/network-behavior/rest
 */

/** Prefixes passed in path with sandboxApi value from config */
export const mswUrl = (path: string): string => new URL(path, sandboxApi).href

/** URLs requests to catch and mock */
export const mockUrls = {
  test: `${new URL(testEndpointUrl, sandboxApi)}`,
  // have to use a wildcard for auth module fetches to be caught
  token: '*/oauth2/token',
  introspect: `*/${introspectURL}`,
  piiAuthenticate: `*/${paylaterAuthenticateURL}`,
  authentication: `*/${authenticateURL}`,
  activationAuthenticate: `*/${activationAuthenticateURL}`,
  complete: `*/${completeURL}`,
  authorize: `*/${authorizeURL}`,
  // a config file that mma mfe requests
  mmaConfig: '*/prod-mma-config.json',
  // services
  accountActivity: `*/${accountActivityUrl}`,
  autopay: `*/${autopayStatusUrl}`,
  enterpriseEligibility: `*/${paymentEnterpriseEligibilityUrl}`,
  paymentMethods: `*/${paymentMethodsUrl}`,
  settlementEligibility: `*/${settlementEligibilityUrl}`,
  accountFeatures: `*/${accountFeaturesUrl}`,
  statements: `*/${statementsUrl}`,
  // for AlertsMFE
  profile: '*/credit/accounts/profile',
  alerts: '*/alerts',
  alertsPreferences: '*/alert-preferences',
  summary: '*/credit/accounts/summary',
  preLoginActivate: `*/${PRE_LOGIN_ACTIVATE_URL}`,
  paymentQuery: `*/${paymentQueryUrl}`
}

const accountTypeMapping: AccountTypeMapping = {
  '2ec7be46-00f2-4367-9ef8-acb42e3a7ca3': 'INSTALLMENT_LOAN_PAY_IN_FOUR',
  'i5k38m4g-0h0g-404g-h7jg-1851i58j7h3g': 'INSTALLMENT_LOAN_MONTHLY'
}

const hostUrl = sandboxApi

// special case response mockers

/** Mock error response to testQuery */
// mswjs.io/docs/migrations/1.x-to-2.x#ctxstatus

export const testErrorGetHandler = https.get(
  mockUrls.test,
  () =>
    new HttpResponse(JSON.stringify(mockAuthData.test(false)), { status: 400 })
)

export const testNoContentPostHandler = https.post(mockUrls.test, () => {
  // https://mswjs.io/docs/basics/mocking-responses#mocking-status-code-and-text
  return new HttpResponse(null, { status: 204, statusText: 'No Content' })
})

export const tokenServicePostErrorHandler = https.post(
  mockUrls.token,
  () => new HttpResponse(null, { status: 422 })
)

export const tokenPostHandler = https.post(mockUrls.token, () => {
  const noActiveAccounts =
    sessionStorage.getItem('no_active_accounts') === 'true'
  return HttpResponse.json({
    ...mockAuthData.token,
    accountIdentifier: noActiveAccounts ? [] : getMockAccountIds()
  })
})

export const tokenErrorPostHandler = https.post(
  mockUrls.token,
  () => new HttpResponse(null, { status: 500 })
)

export const authenticateErrorIovationPostHandler = https.post(
  mockUrls.piiAuthenticate,
  () =>
    new HttpResponse(JSON.stringify(mockAuthData.authenticateErrorIovation), {
      status: 422
    })
)

export const authenticateErrorSessionInvalidPostHandler = https.post(
  mockUrls.piiAuthenticate,
  () =>
    new HttpResponse(JSON.stringify(mockAuthData.authenticateErrorFailed), {
      status: 401
    })
)

export const authenticateErrorSessionNotFoundPostHandler = https.post(
  mockUrls.piiAuthenticate,
  () =>
    new HttpResponse(
      JSON.stringify(mockAuthData.authenticateErrorSessionNotFound),
      {
        status: 422
      }
    )
)

export const authenticateErrorBadRequestPostHandler = https.post(
  mockUrls.piiAuthenticate,
  () =>
    new HttpResponse(JSON.stringify(mockAuthData.authenticateErrorBadRequest), {
      status: 400
    })
)

export const authenticateErrorBlockedAccountPostHandler = https.post(
  mockUrls.authentication,
  () =>
    new HttpResponse(
      JSON.stringify(mockAuthData.authenticateErrorBlockedAccount),
      {
        status: 422
      }
    )
)

/** TODO: Need more research on how to do this handler, maybe using the new syf-api-library to build it */
export const accountSummaryV2HandlerQA = accountSummaryV2GetHandlerPaylater(
  { hostUrl: QA_API_HOST },
  accountTypeMapping
)

/** TODO: We might need to update this function as well */
const accountSummaryV2GetHandler = () => {
  if (getSessionAuthFlow() === SECURED_INSTALLMENTS_FLOW) {
    return https.get(mockUrls.summary, async () => {
      await delay(300)
      return HttpResponse.json(accountSummarySecuredInstallmentsMock())
    })
  }
  return accountSummaryV2GetHandlerPaylater(
    { hostUrl, delayMs: isBrowserEnvironment ? 1500 : 0 },
    accountTypeMapping
  )
}

export const autopayStatusEnrolledGetHandler = https.get(
  mockUrls.autopay,
  async () => {
    await delay(300)
    return HttpResponse.json(mockAutopayStatusEnrolled)
  }
)

export const universalActivationErrorAccountUnsupportedPostHandler = https.post(
  mockUrls.activationAuthenticate,
  () =>
    new HttpResponse(
      JSON.stringify(mockActivationAuthData.accountUnsupportedError),
      {
        status: 422
      }
    )
)

export const universalActivationErrorIovationPostHandler = https.post(
  mockUrls.activationAuthenticate,
  () =>
    new HttpResponse(JSON.stringify(mockActivationAuthData.iovationError), {
      status: 422
    })
)

export const universalActivationErrorMaxAttemptsPostHandler = https.post(
  mockUrls.activationAuthenticate,
  () =>
    HttpResponse.json(mockActivationAuthData.maxAttemptsError, { status: 429 })
)

// default mock response mockers
export const testGetHandler = https.get(
  mswUrl(mockUrls.test),
  ({ request }) => {
    // source: https://mswjs.io/docs/migrations/1.x-to-2.x#request-url
    const url = new URL(request.url)
    const isForceError = url.searchParams.get('error') === 'true'

    if (isForceError) {
      return HttpResponse.json(mockAuthData.test(false), { status: 400 })
    }
    return HttpResponse.json(mockAuthData.test())
  }
)

export const introspectPostHandler = https.post(
  mockUrls.introspect,
  async () => {
    const invalidToken = sessionStorage.getItem('invalid_token') === 'true'
    const expiredToken = sessionStorage.getItem('expired_token') === 'true'
    const delayIntrospect =
      sessionStorage.getItem('delay_introspect') === 'true'
    if (expiredToken) {
      // this fixes a potential infinite loop, if expired token is not reset after the page refreshes
      sessionStorage.setItem('expired_token', 'false')
      return HttpResponse.json(mockAuthData.introspectExpiredToken)
    }
    if (invalidToken) {
      // this fixes a potential infinite loop, if invalid token is not reset after the page refreshes
      sessionStorage.setItem('invalid_token', 'false')
      return HttpResponse.json(mockAuthData.introspectInvalidToken)
    }

    if (delayIntrospect) {
      await sleep(10000)
    }

    return HttpResponse.json(mockAuthData.introspect)
  }
)

export const authenticatePostHandler = https.post(
  mockUrls.authentication,
  () => {
    const accountNotFound =
      sessionStorage.getItem('account_not_found') === 'true'
    if (accountNotFound) {
      return new HttpResponse(
        JSON.stringify(mockAuthData.authenticateErrorSecuredNotFound),
        {
          status: 422
        }
      )
    }
    return HttpResponse.json(mockAuthData.authenticateSecured)
  }
)

export const universalActivationQuickAuthHandler = https.post(
  mockUrls.activationAuthenticate,
  () => {
    const accountNotFound =
      sessionStorage.getItem('account_not_found') === 'true'
    if (accountNotFound) {
      return new HttpResponse(
        JSON.stringify(
          mockActivationAuthData.authenticateErrorUniversalActivationNotFound
        ),
        { status: 422 }
      )
    }
    return HttpResponse.json(mockActivationAuthData.authenticateSuccessResponse)
  }
)

export const universalActivationFetchAccountErrorQuickAuthHandler = https.post(
  mockUrls.activationAuthenticate,
  () =>
    HttpResponse.json(mockActivationAuthData.fetchAccountError, { status: 500 })
)

export const piiAuthenticatePostHandler = https.post(
  mockUrls.piiAuthenticate,
  () => {
    const accountNotFound =
      sessionStorage.getItem('account_not_found') === 'true'
    if (accountNotFound) {
      return new HttpResponse(
        JSON.stringify(mockAuthData.authenticateErrorNotFound),
        {
          status: 422
        }
      )
    }
    return HttpResponse.json(mockAuthData.authenticate)
  }
)

export const completeGetHandler = https.get(mockUrls.complete, () => {
  const clientIdErr = sessionStorage.getItem('client_error') === 'true'
  if (clientIdErr) {
    return new HttpResponse(JSON.stringify(mockAuthData.completeError), {
      status: 422
    })
  }
  return HttpResponse.json(mockAuthData.complete)
})
/** Used by mma-mfe, MAP to retrieve activity */
const accountActivityQueryGetHandler = https.get(mockUrls.accountActivity, () =>
  HttpResponse.json(mockActivity)
)

// some config obj for mma-mfe
const mmaConfigGetHandler = https.get(mockUrls.mmaConfig, () =>
  HttpResponse.json(mockMMAConfig)
)

const accountFeaturesHandler = https.get(mockUrls.accountFeatures, () =>
  HttpResponse.json(mockAccountFeatures)
)

const statementsHandler = https.get(mockUrls.statements, () =>
  HttpResponse.json(mockStatements)
)

/** Used by mma-mfe, MAP */
const autopayStatusGetHandler = https.get(mockUrls.autopay, () =>
  HttpResponse.json(mockAutopayStatus)
)

/** Used by MAP when autopay enrollment succeeds on pre 1st cycle acct */
// https://mswjs.io/docs/api/http-response#new-httpresponsebody-init
const autopayStatusPutHandler = https.put(
  mockUrls.autopay,
  () =>
    new HttpResponse('', {
      headers: {
        'Content-Type': 'application/json'
      },
      status: 202
    })
)

/** Used by payment enterprise eligibility, MAP */
const enterpriseEligibilityGetHandler = https.get(
  mockUrls.enterpriseEligibility,
  () => HttpResponse.json(mockPaymentEnterpriseEligibility)
)

/** Used by payment methods, MAP */
const paymentMethodsGetHandler = https.get(mockUrls.paymentMethods, () =>
  HttpResponse.json(mockPaymentMethods)
)

/** Used by payment plan settlement, MAP */
const settlementEligibilityGetHandler = https.get(
  mockUrls.settlementEligibility,
  () => HttpResponse.json(mockSettlementEligibility)
)

/** Used by MAP */
const postPaymentHandler = https.post(mockUrls.paymentQuery, () => {
  const paymentId = '1234567'
  return HttpResponse.json({ paymentId })
})

/** Used for Alerts MFE */
const profileGetHandler = https.get(mockUrls.profile, () =>
  HttpResponse.json(mockProfileData)
)

const alertsGetHandler = https.get(mockUrls.alerts, () =>
  HttpResponse.json(mockAlertData)
)

const alertsPreferenceGetHandler = https.get(mockUrls.alertsPreferences, () =>
  HttpResponse.json(mockPreferencesData)
)

/** card activation handlers */
const cardActivationPutHandler = https.put(
  mockUrls.preLoginActivate,
  async () => {
    const activationCustomErrorCode = sessionStorage.getItem(
      MOCK_ACTIVATION_CUSTOM_ERROR_CODE
    )
    // https://mswjs.io/docs/migrations/1.x-to-2.x#ctxdelay
    if (activationCustomErrorCode) {
      await delay(2000)
      return new HttpResponse(
        JSON.stringify(
          getActivationCustomErrorResponse(activationCustomErrorCode)
        ),
        { status: 422 }
      )
    }
    await delay(2000)
    return HttpResponse.json(activationSuccessResponse)
  }
)

export const cardActivationExternalStatusErrorHandler = https.put(
  mockUrls.preLoginActivate,
  async () => {
    await delay(2000)
    return new HttpResponse(
      JSON.stringify(cardActivationErrors.externalStatusErrorMock),
      {
        status: 422
      }
    )
  }
)

export const cardActivationInvalidExternalStatusErrorHandler = https.put(
  mockUrls.preLoginActivate,
  async () => {
    await delay(2000)
    return new HttpResponse(
      JSON.stringify(cardActivationErrors.invalidExternalStatusErrorMock),
      { status: 422 }
    )
  }
)

export const cardActivationAuthShellErrorHandler = https.put(
  mockUrls.preLoginActivate,
  async () => {
    await delay(2000)
    return new HttpResponse(
      JSON.stringify(cardActivationErrors.authShellAccountErrorMock),
      {
        status: 422
      }
    )
  }
)

export const cardActivationAlreadyActivatedErrorHandler = https.put(
  mockUrls.preLoginActivate,
  async () => {
    await delay(2000)
    return new HttpResponse(
      JSON.stringify(cardActivationErrors.alreadyActivatedStatusErrorMock),
      { status: 422 }
    )
  }
)

const handlers = [
  introspectPostHandler,
  completeGetHandler,
  accountActivityQueryGetHandler,
  creditAccountsQueryGetHandler({ hostUrl }),
  accountSummaryV2GetHandler(),
  authenticatePostHandler,
  piiAuthenticatePostHandler,
  autopayStatusGetHandler,
  autopayStatusPutHandler,
  tokenPostHandler,
  enterpriseEligibilityGetHandler,
  paymentMethodsGetHandler,
  settlementEligibilityGetHandler,
  postPaymentHandler,
  mmaConfigGetHandler,
  statementsHandler,
  accountFeaturesHandler,
  profileGetHandler,
  alertsGetHandler,
  alertsPreferenceGetHandler,
  cardActivationPutHandler,
  universalActivationQuickAuthHandler
]

export default handlers
