export const tokenPrefix = ''
export const tokenKeyName = 'accessToken'
export const appPlatform = 'appPlatform'
export const tokenVerifier = 'peaksTokenVerifier'
export const sessionIdName = 'correlationId'
export const tokenAccountsName = `accessTokenAccounts`
export const authDomain = 'authDomain'
export const currentSelectedAccountName = 'currentSelectedAccount'
export const tokenRequestTimestamp = 'tokenRequestTimestamp'
export const tokenExpirationTime = 'tokenExpirationTime'
export const logoutCorrelationId = 'logoutCorrelationId'
export const appRegion = 'region'
export const configHost = 'appConfigHost'
export const authFlow = 'authFlow'
export const givenName = 'givenName'
export const ivaShowIcon = 'showIvaChatIcon'
export const platformInformation = 'platformInformation'
export const useProdFlags = 'useProdFlags'
export const callbackErrorLogged = 'callbackErrorLogged'
export const codeVerifier = 'codeVerifier'
export const featureFlagMocking = 'feature_flag_mocking'
export const mockFlags = 'mock_flags'

const tokenName = `${tokenPrefix}${tokenKeyName}`

export default tokenName
