import config from 'const/config'
import { UNIVERSAL_ACTIVATION_FLOW } from 'const/constants'
import { getSessionAuthFlow } from 'helpers/authFlows'

/**
 * This returns the corresponding apigee client id aka api key
 * for different auth flows. This controls which apigee app will be authorized
 * for the current user's session. Activation has a unique use case where
 * the apigee app is separate, which is different than our servicing channel.
 * This is mostly for security reasons as different apigee apps have different
 * abilities, config, and permissions.
 * @returns string - the apigee client id aka api key
 */
export const getApigeeClientId = () => {
  const { APIGEE_CLIENT_ID, APIGEE_ACTIVATION_CLIENT_ID } = config
  const sessionAuthFlow = getSessionAuthFlow()
  // only 1 check because only activation doesn't use our generic apigee client id
  const isUniversalActivation = sessionAuthFlow === UNIVERSAL_ACTIVATION_FLOW

  return isUniversalActivation ? APIGEE_ACTIVATION_CLIENT_ID : APIGEE_CLIENT_ID
}

export default getApigeeClientId
