/* eslint-disable sonarjs/no-duplicate-string */
import config, {
  VITE_MOCK_ACCESS_TOKEN,
  VITE_MOCK_ID_TOKEN
} from 'const/config'
import routes from 'const/routes'
import { TestQueryData } from 'services/testQuery'
import { signInErrorTypes } from 'const/signInErrors'
import {
  GATEWAY_CLIENT_BAD_REQUEST,
  IDENTITY_BUSINESS_ERROR,
  IOVATION_AUTH_ERROR_CODE,
  IOVATION_AUTH_ERROR_MESSAGE
} from 'const/businessErrors'
import getMockAccountIds from '../accountIds/getMockAccountIds'

/** Mock data to send back in mock responses */
const noActiveAccounts = sessionStorage.getItem('no_active_accounts') === 'true'
const mockAuthData = {
  test: (success = true): TestQueryData => ({
    status: success ? 'success' : 'failure'
  }),
  authorizeLoginURL: `/${config.BASENAME}/${routes.LOGIN}?token=mocked`,
  authenticate: {
    links: [
      {
        rel: 'GET',
        href: 'https://api-qa.syf.com/v1/identity/complete',
        name: 'COMPLETE'
      }
    ],
    message: 'Authentication Success'
  },
  authenticateSecured: {
    links: [
      {
        rel: 'GET',
        href: 'https://api-qa.syf.com/v1/identity/complete',
        name: 'COMPLETE'
      }
    ],
    code: 'idp.secured-login.success',
    message: 'Authentication Success'
  },
  authenticateErrorIovation: {
    code: IDENTITY_BUSINESS_ERROR,
    message: 'Business Validation Failure',
    trackingId: '2a4756f5-2cfc-4fae-a854-7d21d04509f2',
    transient: false,
    businessErrors: [
      {
        code: IOVATION_AUTH_ERROR_CODE,
        message: IOVATION_AUTH_ERROR_MESSAGE
      }
    ]
  },
  authenticateErrorFailed: {
    status: 401,
    name: 'AUTH FAILED'
  },
  authenticateErrorGeneralError: {
    trackingId: '06b64a6e-1e93-4d11-a89b-8e587dcca3bc',
    message: 'internal server error'
  },
  authenticateErrorNotFound: {
    code: IDENTITY_BUSINESS_ERROR,
    message: 'Business Validation Failure',
    trackingId: '06b64a6e-1e93-4d11-a89b-8e587dcca3bc',
    transient: false,
    businessErrors: [
      {
        code: signInErrorTypes.PAY_LATER,
        message: 'account not found'
      }
    ]
  },
  authenticateErrorSecuredNotFound: {
    code: IDENTITY_BUSINESS_ERROR,
    message: 'Business Validation Failure',
    trackingId: '06b64a6e-1e93-4d11-a89b-8e587dcca3bc',
    transient: false,
    businessErrors: [
      {
        code: signInErrorTypes.SECURED_INSTALLMENTS,
        message: 'account not found'
      }
    ]
  },
  authenticateErrorSessionNotFound: {
    code: IDENTITY_BUSINESS_ERROR,
    message: 'Business Validation Failure',
    trackingId: '2a4756f5-2cfc-4fae-a854-7d21d04509f2',
    transient: false,
    businessErrors: [
      {
        code: 'idp.interaction.not.found',
        message: 'interaction session not found'
      }
    ]
  },
  authenticateErrorBadRequest: {
    code: GATEWAY_CLIENT_BAD_REQUEST,
    message: 'Received non success response code',
    trackingId: '2a4756f5-2cfc-4fae-a854-7d21d04509f2',
    transient: false,
    errors: [
      {
        field: 'sessionId',
        reason: 'invalid uuid'
      }
    ]
  },
  authenticateErrorBlockedAccount: {
    code: IDENTITY_BUSINESS_ERROR,
    message: 'Business Validation Failure',
    trackingId: '2a4756f5-2cfc-4fae-a854-7d21d04509f2',
    transient: false,
    businessErrors: [
      {
        code: 'idp.authenticate.account.status.error',
        message: 'account status issue'
      }
    ]
  },
  token: {
    token_type: 'Bearer',
    issued_at: '1665610050992',
    client_id: '1',
    link_id: '',
    scope: 'read write',
    expires_in: 1799,
    // this is to keep consistency, despite the token response not being modified
    accountIdentifier: noActiveAccounts ? [] : getMockAccountIds(),
    // defined in the env so fortify security scan doesn't false positive on hardcoded credentials
    access_token: VITE_MOCK_ACCESS_TOKEN,
    session_id: 'd9c4eb73-58e8-4b66-a8f9-0dbdc76e3586',
    id_token: VITE_MOCK_ID_TOKEN,
    status: 'approved'
  },
  tokenPageURL: `/${routes.GET_TOKEN}?state=qLhhkslA&code=AbQ9kWjW&lang=en&client=generic`,
  authPostError: `/${routes.GET_TOKEN}?error=server_error&error_description=The_authorization_server_encountered_an_unexpected_condition&state=KEWRJty49&client=generic`,
  introspect: {
    active: true,
    returnTo: 'https://qa.synchronycredit.com/quickaccess/',
    interactionId: 'somevalue',
    consumerKey: 'somekey',
    flowId: 'pii'
  },
  introspectExpiredToken: {
    active: false,
    returnTo: 'https://dev.synchronycredit.com/quickaccess/'
  },
  introspectInvalidToken: {
    active: false
  },
  complete: {
    links: [
      {
        rel: 'POST',
        href: `https://auth-qa.syf.com/v3/oauth2/authorize?id_token_hint=${VITE_MOCK_ACCESS_TOKEN}`,
        name: 'FORM-POST'
      }
    ],
    clientId: 'generic',
    prevLast4: '',
    currentLast4: '7891',
    code: 'idp.complete.success'
  },
  completeError: {
    code: IDENTITY_BUSINESS_ERROR,
    message: 'Business Validation Failure',
    trackingId: 'b94b3b7c-8bdc-4f53-8c8c-aa1749d80d6f',
    transient: false,
    businessErrors: [
      {
        code: 'idp.error.clientid',
        message: 'client id data not found'
      }
    ]
  }
}

export default mockAuthData
