/* eslint-disable sonarjs/no-duplicate-string */
import { AccountActivityGetResponse } from 'syf-typings'

export { default as getMockAccountIds } from './accountIds/getMockAccountIds'
export { default as mockAuthData } from './auth/mockAuthData'

export const mockActivity: AccountActivityGetResponse = {
  activity: [
    {
      type: 'PAYMENT',
      status: 'POSTED',
      description: 'One-Time Payment (Online)',
      postedDate: '2021-05-24T14:15:22Z',
      createdDate: '2021-05-23T14:15:22Z',
      paymentType: 'ONE_TIME_PAYMENT',
      currencyCode: '2021-05-24T14:15:22Z',
      transactionInfo: {
        transactionCode: '7',
        cardEntryMethod: 'test',
        promoCode: 'test',
        skuReferenceNumber: 'test',
        skuReferenceDetails: ['test'],
        transactionId: 'test',
        merchant: {
          categoryCode: 'test',
          description: 'test',
          name: 'test',
          city: 'test',
          state: 'test',
          zip: '123456',
          addressLine1: 'test',
          addressLine2: 'test'
        }
      },
      amount: '-100.00',
      identifiers: [],
      paymentInfo: {
        scheduledDate: '2021-05-24T14:15:22Z',
        modifiedDate: '2021-05-24T14:15:22Z',
        id: '1234',
        channel: 'ONLINE'
      }
    },
    {
      type: 'INTEREST',
      paymentType: 'ONE_TIME_PAYMENT',
      status: 'POSTED',
      description: 'STORE 0781 HUBER HEIGHTS OH DEFERRED INTEREST WITH PAYMENT',
      postedDate: '2021-05-24T14:15:22Z',
      createdDate: '2021-05-23T14:15:22Z',
      currencyCode: '2021-05-23T14:15:22Z',
      amount: '55.50',
      identifiers: [],
      transactionInfo: {
        transactionCode: '7',
        cardEntryMethod: 'test',
        promoCode: 'test',
        skuReferenceNumber: 'test',
        skuReferenceDetails: ['test'],
        transactionId: 'test',
        merchant: {
          categoryCode: 'test',
          description: 'test',
          name: 'test',
          city: 'test',
          state: 'test',
          zip: '123456',
          addressLine1: 'test',
          addressLine2: 'test'
        }
      },
      paymentInfo: {
        scheduledDate: '2021-05-24T14:15:22Z',
        modifiedDate: '2021-05-24T14:15:22Z',
        id: '1234',
        channel: 'ONLINE'
      }
    }
  ]
}

export const mockAutopayStatus = { enrollment: false }

export const mockAutopayStatusEnrolled = {
  enrollment: true,
  paymentMethod: {
    bankAccountNumberLast4: '5999',
    bankRoutingNumber: '469556852',
    type: 'SAVINGS',
    id: 'vjlhmmbhpt',
    name: 'Kris LLC',
    nickname: '',
    bankChannel: 'DIGITAL',
    paymentMedium: 'E_CHECK',
    status: ''
  },
  paymentMedium: 'E_CHECK',
  enrollmentDetails: {
    effectiveCurrentCycle: true,
    nextDueDate: '2023-11-13',
    paymentOption: 'MINIMUM_PAYMENT_DUE'
  }
}

export const mockPaymentEnterpriseEligibility = {
  ALLOWED_PAYMENT_METHODS: [
    {
      subEligibilityType: 'E_CHECK',
      eligible: true,
      criteria: [
        { attribute: 'accountType', value: 'CHECKING' },
        { attribute: 'accountType', value: 'SAVINGS' }
      ]
    },
    { subEligibilityType: 'PINLESS_DEBIT_CARD', eligible: true }
  ],
  AUTOPAY: [
    {
      subEligibilityType: 'AUTOPAY_ENROLL',
      eligible: true,
      criteria: [
        { attribute: 'effectiveDueDate', value: '' },
        { attribute: 'effectiveCurrentCycle', value: true }
      ]
    },
    {
      subEligibilityType: 'AUTOPAY_MODIFY',
      eligible: false,
      criteria: [{ attribute: 'reason', value: 'statement not generated' }]
    },
    {
      subEligibilityType: 'AUTOPAY_UNENROLL',
      eligible: false,
      criteria: [
        {
          attribute: 'reason',
          value: 'clear outstanding amount'
        }
      ]
    }
  ],
  PAYMENT_OPTION: [
    {
      subEligibilityType: 'AUTOPAY',
      eligible: true,
      criteria: [
        { attribute: 'WAIVED_INTEREST_AMOUNT', value: 'false' },
        { attribute: 'MINIMUM_PAYMENT_DUE', value: 'true' }
      ]
    },
    {
      subEligibilityType: 'ONE_TIME_PAYMENT',
      eligible: true,
      criteria: [
        { attribute: 'WAIVED_INTEREST_PAYOFF_AMOUNT', value: 'false' },
        { attribute: 'STANDARD_LOAN_PAYOFF_AMOUNT', value: 'true' },
        { attribute: 'WAIVED_INTEREST_AMOUNT', value: 'true' },
        { attribute: 'MINIMUM_PAYMENT_DUE', value: 'true' },
        { attribute: 'OTHER_AMOUNT', value: 'true' }
      ]
    }
  ]
}

export const mockPaymentMethods = {
  paymentMethods: [
    {
      bankAccountNumberLast4: '7152',
      bankChannel: 'DIGITAL',
      bankRoutingNumber: '052619258',
      dateCreated: '2023-10-11',
      dateLastPaymentScheduled: '2023-10-04',
      id: 'srldpxsdtk',
      name: 'Walsh - Pollich',
      nickname: '',
      paymentMedium: 'E_CHECK',
      type: 'SAVINGS'
    },
    {
      id: 'ksxbpjofgg',
      cardNumberLast4: '1652',
      dateCreated: '2023-09-28',
      dateLastPaymentScheduled: '2023-10-01',
      paymentMedium: 'PINLESS_DEBIT_CARD'
    }
  ]
}

export const mockSettlementEligibility = {
  eligible: false,
  totalBalanceAmount: '531.5',
  allowedSettlementPercentage: '60',
  allowedSettlementAmount: '318.9',
  paymentPlans: null,
  paymentArrangementPlans: [
    {
      paymentDueDate: '2023-06-10',
      paymentAmount: '99.89'
    },
    {
      paymentDueDate: '2023-07-10',
      paymentAmount: '99.89'
    },
    {
      paymentDueDate: '2023-08-10',
      paymentAmount: '102.92'
    }
  ]
}

export const mockMMAConfig = {
  name: 'mma-mfe',
  config: {
    flags: {
      enrichment: true,
      cycleDateFilter: true,
      disputes: true
    }
  }
}

export const mockAccountFeatures = {
  locator: null,
  offers: null,
  accessToStatements: true,
  accessToDisputes: true,
  accessToCreditBalanceRefund: false,
  rewardDetails: {
    accessToRewards: true,
    modeOfService: 'DESKTOP_AND_MOBILE',
    rewardsPlatformDestination: null
  }
}

export const mockStatements = {
  statements: [
    {
      statementDate: '2023-05-09',
      statementDetails: {
        statementInsert: null,
        statementStartDate: '2023-04-08',
        statementEndDate: '2023-05-09',
        statementAmount: '2488.50'
      }
    },
    {
      statementDate: '2023-04-07',
      statementDetails: {
        statementInsert: null,
        statementStartDate: '2023-03-10',
        statementEndDate: '2023-04-07',
        statementAmount: '2505.82'
      }
    },
    {
      statementDate: '2023-03-09',
      statementDetails: {
        statementInsert: null,
        statementStartDate: '2023-02-07',
        statementEndDate: '2023-03-09',
        statementAmount: '1088.00'
      }
    },
    {
      statementDate: '2023-02-06',
      statementDetails: {
        statementInsert: null,
        statementStartDate: '2023-01-10',
        statementEndDate: '2023-02-06',
        statementAmount: '1039.13'
      }
    }
  ]
}
