import { Preferences } from './mockPreferencesData.types'

export const mockPreferencesData: Preferences = {
  alerts: [
    {
      id: 'AUTOPAY_CANCEL',
      description:
        'Alert will be triggered when user cancels the autopay option',
      delivery: {
        email: {
          enrolled: true,
          changeable: false
        },
        text: {
          enrolled: false,
          changeable: true
        }
      }
    },
    {
      id: 'AUTOPAY_ENROLL',
      description:
        'Alert will be triggered when user enrolls into an autopay option',
      delivery: {
        email: {
          enrolled: true,
          changeable: false
        },
        text: {
          enrolled: false,
          changeable: true
        }
      }
    },
    {
      id: 'AUTOPAY_MODIFY',
      description:
        'Alert will be triggered when user modifies the autopay option',
      delivery: {
        email: {
          enrolled: true,
          changeable: false
        },
        text: {
          enrolled: false,
          changeable: true
        }
      }
    },
    {
      id: 'PAYMENT_POSTED',
      description: 'User will get notification when payment gets posted',
      delivery: {
        email: {
          enrolled: false,
          changeable: false
        },
        text: {
          enrolled: false,
          changeable: true
        }
      }
    },
    {
      id: 'PAYMENT_SCHEDULED',
      description:
        'user will be notified if the payment is scheduled for an account',
      delivery: {
        email: {
          enrolled: true,
          changeable: false
        },
        text: {
          enrolled: false,
          changeable: true
        }
      }
    },
    {
      id: 'INSTALLMENT_CREDIT_AUTOPAY_REMINDER',
      description: 'User will be sent out a reminder for autopay',
      delivery: {
        email: {
          enrolled: true,
          changeable: false
        },
        text: {
          enrolled: false,
          changeable: true
        }
      },
      attributes: {
        reminderDueDays: {
          description: 'User specified days preference value',
          changeable: false,
          value: '5'
        }
      }
    },
    {
      id: 'INSTALLMENT_CREDIT_PAYMENT_RETURNED',
      description: 'User will be notified when autopay returns',
      delivery: {
        email: {
          enrolled: true,
          changeable: false
        },
        text: {
          enrolled: false,
          changeable: true
        }
      }
    },
    {
      id: 'INSTALLMENT_CREDIT_PAYMENT_REMINDER',
      description: 'User will be sent out a reminder for payment',
      delivery: {
        email: {
          enrolled: true,
          changeable: false
        },
        text: {
          enrolled: false,
          changeable: true
        }
      }
    },
    {
      id: 'INSTALLMENT_CREDIT_ACCOUNT_SUMMARY',
      description: 'User will be sent out with loan summary',
      delivery: {
        email: {
          enrolled: true,
          changeable: false
        },
        text: {
          enrolled: false,
          changeable: true
        }
      }
    },
    {
      id: 'INSTALLMENT_CREDIT_LOAN_COMPLETE',
      description: 'user will be present with loan account closure',
      delivery: {
        email: {
          enrolled: true,
          changeable: false
        },
        text: {
          enrolled: false,
          changeable: true
        }
      }
    }
  ],
  smsConsent: false
}
